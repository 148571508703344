import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { appWithTranslation, SSRConfig } from 'next-i18next';
import { useEffect } from 'react';

import '@sy/component-library/src/styles/normalize.css';
import '@sy/component-library/src/fonts/font-face.css';

import SocialMetadata from '~/components/SocialMetadata';
import type { Metadata } from '~/types';
import {
	DEFAULT_META_DESCRIPTION,
	DEFAULT_META_TITLE,
	DEBUG,
	IS_CLIENT_SIDE,
	IS_BUILDING,
} from '~/config';

import i18nConfig from '../../next-i18next.config';
import Prefetch from '../components/Prefetch';
import FullStoryScript from '../components/scripts/fs';
import type { FeatureFlags } from '../getServerSideProps/contextDecorators/withFeatureFlags/featureFlags';

// TODO SSR hack for missing Element in Server
if (typeof Element === 'undefined') {
	(global as any).Element = () => {};
}

// TODO SSR hack for missing navigator in Server
if (typeof navigator === 'undefined') {
	(global as any).navigator = {};
}

if (IS_BUILDING && !IS_CLIENT_SIDE) {
	// at build time, we are agnostic of the environment we are building for, so we cannot know the env variables
	global.SY_BROWSER = {
		env: {} as any,
	};
}

if (!IS_BUILDING && !IS_CLIENT_SIDE) {
	const {
		getFrontendPublicProcessEnvVars,
		// eslint-disable-next-line global-require
	} = require('@sy/frontend-bridge-resources');

	// eslint-disable-next-line global-require
	const { getEnvironment } = require('../config.environment');
	global.SY_BROWSER = {
		env: getFrontendPublicProcessEnvVars(getEnvironment()),
	};
}

(global as any).I18N_AVAILABLE_LANGUAGES = createAvailableLanguages();

// TODO SSR hack to hide multiple react versions error in dev, this is originated by app-client using styled-components 5.1.1
if (DEBUG) {
	// eslint-disable-next-line no-console
	const originalConsoleError = console.error;
	try {
		// eslint-disable-next-line no-console
		console.error = (msg, ...rest) => {
			if (typeof msg === 'string') {
				const isKnownReactHookError =
					msg.includes('Warning: Invalid hook call.') &&
					msg.includes('https://reactjs.org/link/invalid-hook-call');
				if (isKnownReactHookError) {
					return;
				}

				originalConsoleError(msg, ...rest);
			}
		};
	} catch (error) {}
}

type PageProps = {
	featureFlags: FeatureFlags;
	user: { sessionId: string };
	nonce: string;
} & SSRConfig;

const MyApp = ({
	Component,
	pageProps,
}: AppProps<
	PageProps & {
		metadata?: Metadata;
	}
>) => {
	const {
		featureFlags,
		user,
		nonce,
		metadata: { title, description, canonical, ...restMetadata } = {},
	} = pageProps;

	const REDUX_DATA = buildReduxData({ featureFlags, user });
	const PUBLIC_ENV_VARS = buildPublicEnvVars(global.SY_BROWSER.env);

	useEffect(() => {
		if (!featureFlags) {
			return;
		}

		import('@sy/app-client/src/ssrAdapter/services/logger').then(
			({ default: logger }) => {
				logger.setFeatures(featureFlags);
			},
		);
	}, [featureFlags]);

	if (!IS_CLIENT_SIDE) {
		(global as any).ssr = { featureFlags };
	}

	return (
		<>
			<Head>
				{REDUX_DATA && (
					<script
						id="feature-flags"
						nonce={nonce}
						dangerouslySetInnerHTML={{
							__html: `window.REDUX_DATA = ${REDUX_DATA}`,
						}}
					/>
				)}
				{PUBLIC_ENV_VARS && (
					<script
						id="public-process-env"
						nonce={nonce}
						dangerouslySetInnerHTML={{
							__html: `window.SY_BROWSER = { env : ${PUBLIC_ENV_VARS} }`,
						}}
					/>
				)}
				{global.SY_BROWSER.env?.FACEBOOK_CLIENT_ID && (
					<meta
						name="fb:app_id"
						content={global.SY_BROWSER.env.FACEBOOK_CLIENT_ID}
					/>
				)}
				{global.SY_BROWSER.env?.ASSETS_HOST && (
					<>
						<link
							rel="icon"
							type="image/x-icon"
							href={`${global.SY_BROWSER.env.ASSETS_HOST}/assets/v1/favicon.ico`}
						/>
						<link
							rel="shortcut icon"
							type="image/x-icon"
							href={`${global.SY_BROWSER.env.ASSETS_HOST}/assets/v1/favicon.ico`}
						/>
						<link
							rel="icon"
							type="image/png"
							sizes="16x16"
							href={`${global.SY_BROWSER.env.ASSETS_HOST}/assets/v1/favicon-16x16.png`}
						/>
						<link
							rel="icon"
							type="image/png"
							sizes="32x32"
							href={`${global.SY_BROWSER.env.ASSETS_HOST}/assets/v1/favicon-32x32.png`}
						/>
						<link
							rel="icon"
							type="image/png"
							sizes="192x192"
							href={`${global.SY_BROWSER.env.ASSETS_HOST}/assets/v1/android-chrome-192x192.png`}
						/>
						<link
							rel="icon"
							type="image/png"
							sizes="512x512"
							href={`${global.SY_BROWSER.env.ASSETS_HOST}/assets/v1/android-chrome-512x512.png`}
						/>
						<link
							rel="apple-touch-icon"
							sizes="180x180"
							href={`${global.SY_BROWSER.env.ASSETS_HOST}/assets/v1/apple-touch-icon.png`}
						/>
						<link
							rel="apple-touch-icon"
							href={`${global.SY_BROWSER.env.ASSETS_HOST}/assets/v1/apple-touch-icon-precomposed.png`}
						/>
						<link
							rel="manifest"
							href={`${global.SY_BROWSER.env.ASSETS_HOST}/assets/v1/site.webmanifest`}
						/>
						<link
							rel="mask-icon"
							href={`${global.SY_BROWSER.env.ASSETS_HOST}/assets/v1/safari-pinned-tab.svg`}
							color="#0d7478"
						/>
						<meta
							name="msapplication-config"
							content={`${global.SY_BROWSER.env.ASSETS_HOST}/assets/v1/browserconfig.xml`}
						/>
					</>
				)}
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<title>{title || DEFAULT_META_TITLE}</title>
				<meta
					name="description"
					content={description || DEFAULT_META_DESCRIPTION}
					key="description"
				/>
				{canonical && <link rel="canonical" href={canonical} />}
			</Head>
			<Script id="fullstory-setup-script" nonce={nonce} type="text/javascript">
				window._fs_capture_on_startup=false;
			</Script>
			<FullStoryScript nonce={nonce} featureFlags={featureFlags} />
			<SocialMetadata {...restMetadata} />
			<Prefetch />
			<div id="app">
				<Component {...pageProps} />
			</div>
		</>
	);
};

function buildReduxData({
	featureFlags,
	user,
}: {
	featureFlags: FeatureFlags;
	user: { sessionId: string };
}) {
	try {
		return JSON.stringify({
			features: featureFlags,
			user: { sessionId: user.sessionId },
		});
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error building redux data', error);
		return null;
	}
}

function buildPublicEnvVars(publicEnvVars: typeof global.SY_BROWSER.env) {
	try {
		return JSON.stringify(publicEnvVars);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error building public env vars', error);
		return null;
	}
}

function createAvailableLanguages(): any {
	const { i18n, localeDisplayNames } = i18nConfig;
	const { locales } = i18n;

	return locales.map((localeCode) => {
		return {
			code: localeCode,
			label: localeDisplayNames[localeCode as keyof typeof localeDisplayNames],
		};
	});
}

export default appWithTranslation(MyApp);
